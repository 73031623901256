import { Injectable } from '@angular/core'
import { HttpBaseService } from '@services/http-base.service'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { Subject, take } from 'rxjs'
import { NotificationService } from '../../../services/notification.service'
import { DeviceInfo, SessionData } from '../../../services/proficloud.interfaces'
import { ProficloudService } from '../../../services/proficloud.service'
import { DeviceStore } from '../stores/device.store'
import { DeviceService } from './device.service'
import { getFirmwareUpdateAllowState } from './firmware.service'

interface DeviceData {
  createdDate: string
  endpointId: string
  metadataUpdatedDate: string
  metadata: {
    address: string
    comment: string
    comment_formatted: string
    con_DeviceType_formatted: string
    con_DeviceType: string
    con_FirmwareVersion_formatted: string
    con_HardwareVersion_formatted: string
    con_SerialNumber_formatted: string
    con_connected_formatted: string
    con_connected: boolean
    con_connected_ts: string
    con_connected_ts_formatted: string
    createdDate_formatted: string
    deviceName: string
    organizationId: string
    trafficLightMessage_formatted: string
    trafficLightNumber: 0 | 1 | 2
    uuid: string
    uuid_formatted: string
    virtual: boolean
  }
}

interface EndpointUnregisteredData {
  endpointId: string
}

@Injectable({
  providedIn: 'root',
})
export class DeviceEventHandlerService {
  private deviceEventSource: EventSourcePolyfill

  pageChanged$ = new Subject<{ pageIndex: number; pageSize: number } | false>()

  constructor(
    private deviceService: DeviceService,
    private deviceStore: DeviceStore,
    private proficloud: ProficloudService,
    private notificationService: NotificationService,
    private httpBase: HttpBaseService
  ) {
    this.proficloud.organisationsListed$.pipe(take(1)).subscribe(() => {
      console.log('device event handler service: orgs listed')
      this.initiateDeviceEventListener()
    })

    // Reload the device event listener once an organization got switched
    proficloud.organisationSwitched$.subscribe(() => {
      this.deviceEventSource?.close()
      this.initiateDeviceEventListener()
    })
  }

  public initiateDeviceEventListener() {
    const url = `${this.httpBase.backendUrls.deviceEventsUrl}`
    this.deviceEventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `Bearer ${(this.proficloud.keycloakData.session as SessionData).access_token}`,
      },
    })

    this.deviceEventSource.addEventListener(`metadata_updated_${this.proficloud.currentOrganisation.organizationId}`, (evt: MessageEvent) => {
      const metaDataUpdateData: DeviceData = JSON.parse(evt.data)
      this.metadataUpdateHandler(metaDataUpdateData)
    })

    this.deviceEventSource.addEventListener(`endpoint_registered_${this.proficloud.currentOrganisation.organizationId}`, (evt: MessageEvent) => {
      const endpointRegisteredData: DeviceData = JSON.parse(evt.data)
      this.endpointRegisteredHandler(endpointRegisteredData)
    })

    this.deviceEventSource.addEventListener(`endpoint_unregistered_${this.proficloud.currentOrganisation.organizationId}`, (evt: MessageEvent) => {
      const endpointUnregisteredData: EndpointUnregisteredData = JSON.parse(evt.data)
      this.endpointUnregisteredHandler(endpointUnregisteredData)
    })
  }

  private metadataUpdateHandler(metaDataUpdateData: DeviceData) {
    const device = this.deviceService.devices.find((ep) => ep.endpointId === metaDataUpdateData.endpointId)

    // If the device has just been added, it is possible that it won't be in kaaData.devices yet
    if (device) {
      device.metadata.trafficLightNumber = metaDataUpdateData.metadata.trafficLightNumber
      device.metadata.trafficLightMessage = metaDataUpdateData.metadata.trafficLightMessage_formatted
      device.metadata.con_connected = metaDataUpdateData.metadata.con_connected
      device.metadata.con_FirmwareVersion = metaDataUpdateData.metadata.con_FirmwareVersion_formatted
      device.metadata.con_DeviceType = metaDataUpdateData.metadata.con_DeviceType
      device.metadata.comment = metaDataUpdateData.metadata.comment
      device.metadata.con_connected_ts = metaDataUpdateData.metadata.con_connected_ts

      this.deviceService.ensureTrafficLight(device)
      this.deviceService.setFormattedValues(device)
      device.firmwaredUpdateAllowState = getFirmwareUpdateAllowState(device)

      // Re-apply existing sorting and filtering if present
      this.deviceService.reFilterAndSort$.next()
      this.deviceService.devicesUpdated$.next()

      // Disabled because sometimes a lot of notifications are arriving, this needs to be fixed in EPR
      /*this.notificationService.addInternalNotification(
        `The device ${device.metadata.deviceName} has been edited. For more information please open the Device Manager`,
        'info',
        new Date(),
        'device-management'
      )*/
    } else {
      console.error("Metadata Update: can't update a device since it is not present in the list of devices.")
    }
  }

  private endpointRegisteredHandler(endpointRegisteredData: DeviceData) {
    const device = this.deviceService.devices.find((ep) => ep.endpointId === endpointRegisteredData.endpointId)

    const addNotification = (device: DeviceInfo) => {
      this.notificationService.addInternalNotification(
        `The device ${device.metadata.deviceName} has been added. For more information please open the Device Manager`,
        'info',
        new Date(),
        'device-management'
      )
    }

    // only add device if not already in list, which can happen if the very same user of this session added it
    if (device) {
      addNotification(device)
    } else {
      // Device list hasn't completed yet
      this.deviceStore.devices$.pipe(take(1)).subscribe(() => {
        const device = this.deviceService.devices.find((ep) => ep.endpointId === endpointRegisteredData.endpointId)
        if (device) {
          addNotification(device)
        } else {
          console.warn('device not found')
        }
      })
    }
  }

  private endpointUnregisteredHandler(endpointUnregisteredData: EndpointUnregisteredData) {
    const device = this.deviceService.devices.find((ep) => ep.endpointId === endpointUnregisteredData.endpointId)
    if (device) {
      this.deviceService.devices = this.deviceService.devices.filter((d) => d.endpointId !== endpointUnregisteredData.endpointId)
      this.notificationService.addInternalNotification(
        `The device ${device.metadata.deviceName} has been deleted. For more information please open the Device Manager`,
        'warning',
        new Date(),
        'device-management'
      )
    }
  }
}
