<div style="padding: 0px; display: flex; flex-direction: column; flex-grow: 1; box-sizing: border-box">
  <div style="background: none; flex-grow: 1; overflow-y: scroll; height: 1px">
    <router-outlet></router-outlet>
  </div>

  <!-- add billing dialog -->
  @if (billing.showBillingAddressRequired$ | async) {
    <pc-overlay [config]="requiredDialog" (closeClick)="billing.showBillingAddressRequired$.next(false)">
      <ng-container body>
        <div style="font-size: 18px">{{ 'STORE.CONFIRM_BILLING_INFORMATION' | translate }}</div>
      </ng-container>
      <flex-row-end buttons style="justify-content: flex-end">
        <pc-button id="edit-save" [text]="'STORE.ENTER_BILLING_INFORMATION' | translate" (btnClick)="onContinueClick()"></pc-button>
      </flex-row-end>
    </pc-overlay>
  }

  <!-- billing form -->

  @if (billing.showBillingAddressForm$ | async) {
    <app-billing-address-overlay
      (closeClick)="billing.showBillingAddressForm$.next(false)"
      (submitClick)="onBillingAccountFormSubmit()"></app-billing-address-overlay>
  }

  <!-- checkout (todo) -->
  @if (billing.showCheckoutForm$ | async) {
    <pc-overlay [config]="checkoutDialog" (closeClick)="billing.showCheckoutForm$.next(false)">
      <ng-container body>
        <app-cart-calculation [shoppingCart]="billing.billingDetails.shoppingCart" style="display: block; width: 100%"></app-cart-calculation>
        <div style="width: 100%">
          <!-- middle: free text reference -->
          <pc-input-textfield
            [control]="billing.orderReferenceControl"
            placeholder="Your reference (optional)"
            size="small"
            variant="fit-width"
            (valueChanged)="billing.referenceInput = $event"></pc-input-textfield>
          <flex-row-between>
            <flex-col style="width: 45%">
              <div>
                <h4>{{ 'STORE.BILLING_ADDRESS' | translate }}</h4>
                <div>{{ billing.billingAccount?.companyName || 'no company name given' }}</div>
                <div>{{ billing.billingAccount?.firstName }} {{ billing.billingAccount?.lastName }}</div>
                <div>{{ billing.billingAccount?.address.street }} {{ billing.billingAccount?.address.houseNumber }}</div>
                <div>{{ billing.billingAccount?.address.postalCode }} {{ billing.billingAccount?.address.city }}</div>
                <div>{{ billing.countryMapping[billing.currentCountry] }}</div>
              </div>
            </flex-col>
            <flex-col style="width: 45%">
              <!-- TODO: Replace function with pipe! -->
              @if (billing.getContractPartner(); as contractPartner) {
                <div>
                  <h4>{{ 'STORE.CONTRACT_PARTNER' | translate }}</h4>
                  <div>{{ contractPartner.companyName }}</div>
                  <div style="white-space: pre">{{ contractPartner.companyAddress }}</div>
                </div>
              }
            </flex-col>
          </flex-row-between>
          <!-- bottom: billing address -->
          <h4>{{ 'STORE.CONFIRMATION_EMAIL' | translate }}</h4>
          <!-- t&c -->
          <flex-row>
            <!-- left: checkbox -->
            <div>
              <input type="checkbox" id="termsCheckbox" id="termsCheck" #termsCheck (change)="termsAccepted = $event.target.checked" />
            </div>
            <!-- right: wording -->
            <div>
              <label
                for="termsCheckbox"
                style="cursor: default"
                [innerHtml]="
                  'STORE.TERMS_AND_CONDITIONS' | translate: { param1: billing.links.termsAndConditions, param2: billing.links.softwareLicenseTerms }
                "></label>
            </div>
          </flex-row>
        </div>
        <!-- If Stripe enaled country -->
        @if (billing.isStripeCountry(billing.billingAccount?.address.country)) {
          <div>
            <ng-container>
              <div style="background: #f5f5f5">
                <h4>{{ 'STORE.DIRECT_PAY' | translate }}</h4>
                <label>{{ 'STORE.PAYMENT_METHOD' | translate }} </label>
                <app-icon style="width: 155px; height: 64px" [name]="'stripe'" [color]="'#635BFF'" data-analytics="devices-map-icon"></app-icon>
              </div>
              <div style="font-weight: 400; font-style: italic; font-size: 16px; line-height: 20px; margin-top: 8px">
                <label>{{ 'STORE.PAYMENT_PROVIDER' | translate }} </label>
              </div>
            </ng-container>
          </div>
        }
        <!-- full width content -->
      </ng-container>
      <flex-row-end buttons style="justify-content: flex-end">
        <pc-button id="purchase-license" [disabled]="!termsCheck.checked" [text]="'STORE.ORDER_NOW' | translate" (btnClick)="submitCheckoutForm()"></pc-button>
      </flex-row-end>
    </pc-overlay>
  }

  <!-- confirmation -->
  @if (billing.showConfirmationForm$ | async) {
    <pc-overlay [config]="confirmationDialog" (closeClick)="billing.showConfirmationForm$.next(false)">
      <ng-container body>
        <div style="width: 100%">
          <h3>{{ 'STORE.SUBSCRIPTION_ID' | translate }}</h3>
          <div style="font-size: 20px; margin-bottom: 20px">{{ billing.bookedSubscription.referenceCode }}</div>
          <app-cart-calculation [shoppingCart]="billing.billingDetails.shoppingCart"></app-cart-calculation>
        </div>
      </ng-container>
      <flex-row-end buttons style="justify-content: flex-end">
        <pc-button variant="secondary" [text]="'STORE.GO_TO_SERVICE' | translate" (btnClick)="submitConfirmationForm()"></pc-button>
        <!-- TODO: Remove function call from if statement -->
        @if (getAssignWording(billing.bookedSubscription.smartServiceId); as wording) {
          <div style="margin-left: 20px">
            <pc-button [text]="wording" (btnClick)="onAssignButtonClick()"></pc-button>
          </div>
        }
      </flex-row-end>
    </pc-overlay>
  }
  @if (showStripeSuccess) {
    <pc-overlay [config]="successConfirmationDialog" (closeClick)="closeDialog()">
      <ng-container body>
        <div style="width: 100%">
          <app-icon class="stripe-dialog" [name]="'check'" [color]="'#09AE3B'"></app-icon>
          <label>{{ 'STORE.STRIPE_SUCCESS_MSG' | translate }}</label>
        </div>
      </ng-container>
    </pc-overlay>
  }
  @if (showStripeFail) {
    <pc-overlay [config]="failConfirmationDialog" (closeClick)="closeDialog()">
      <ng-container body>
        <div style="width: 100%">
          <app-icon class="stripe-dialog" [name]="'cross'" [color]="'#F51423'"></app-icon>
          <label>{{ 'STORE.STRIPE_FAILURE_MSG' | translate }}</label>
        </div>
      </ng-container>
    </pc-overlay>
  }
  <!-- tsd UPGRADE confirmation  -->
  @if (billing.showUpgradeConfirmationForm$ | async) {
    <pc-overlay [config]="{ title: 'Upgrade Confirmation' }" (closeClick)="billing.showUpgradeConfirmationForm$.next(false)">
      <ng-container body>
        <div style="width: 100%">
          <h3>{{ 'STORE.SUBSCRIPTION_ID' | translate }}</h3>
          <div style="font-size: 20px; margin-bottom: 20px">{{ billing.tsdSubscription.referenceCode }}</div>
          <app-cart-calculation [tsdUpgrade]="true"></app-cart-calculation>
        </div>
      </ng-container>
      <flex-row-end buttons style="justify-content: flex-end">
        <pc-button variant="secondary" [text]="'STORE.GO_TO_SERVICE' | translate" (btnClick)="submitConfirmationForm()"></pc-button>
        @if (billing.upgradedSubscription.smartServiceId === 'tsd') {
          <div style="margin-left: 20px">
            <pc-button [text]="getAssignWording('tsd')" (btnClick)="this.billing.showAssignMetricsModal$.next(true)"></pc-button>
          </div>
        }
      </flex-row-end>
    </pc-overlay>
  }

  <!-- contact us to upgrade modal -->
  @if (billing.showContactRequired$ | async) {
    <pc-overlay [config]="{ title: 'Please contact us' }" (closeClick)="billing.showContactRequired$.next(false)">
      <ng-container body>
        <div style="width: 100%; font-size: 20px">
          {{ 'STORE.CHANGE_INFO' | translate }}
          <div style="margin-top: 20px">
            <pc-button [text]="'STORE.CONTACT_US' | translate" (btnClick)="ui.openURL('https://proficloud.io/contact')"></pc-button>
          </div>
        </div>
      </ng-container>
    </pc-overlay>
  }

  <!-- contact us to upgrade modal EMS -->
  @if (billing.showContactRequiredEms$ | async) {
    <pc-overlay [config]="{ title: 'Please contact us' }" (closeClick)="billing.showContactRequiredEms$.next(false)">
      <ng-container body>
        <div style="width: 100%; font-size: 20px">
          {{ 'STORE.CHANGE_INFO_EMS' | translate }}
          <div style="margin-top: 20px">
            <pc-button [text]="'STORE.CONTACT_US' | translate" (btnClick)="ui.openURL('https://proficloud.io/contact')"></pc-button>
          </div>
        </div>
      </ng-container>
    </pc-overlay>
  }

  <!-- upgrade preview overlay -->
  @if (billing.showUpgradeDetails$ | async) {
    <pc-overlay [config]="{ title: 'Subscription Upgrade' }" (closeClick)="closeUpgradeDialog()">
      <ng-container body>
        <!-- row 1: current details -->
        <flex-row style="width: 100%">
          <flex-col style="width: 50%">
            <b>{{ 'STORE.SUBSCRIPTION_ID' | translate }}</b>
            <div>{{ billing.billingDetails.upgradePreview?.subscription.referenceCode }}</div>
          </flex-col>
          <flex-col style="width: 50%">
            <b>{{ 'STORE.BOOKED_ON' | translate }}</b>
            <div>{{ billing.billingDetails.upgradePreview?.subscription.startDate | date }}</div>
          </flex-col>
        </flex-row>
        <!-- upgrade section -->
        <div style="width: 100%; margin-top: 20px">
          <b>{{ 'STORE.PACKAGE_UPGRADE' | translate }}</b>
          <!-- container -->
          <flex-row style="background: #eee">
            <!-- left (old) -->
            <flex-col style="width: 50%; padding: 10px; box-sizing: border-box">
              <b>{{ 'STORE.PREVIOUS_PACKAGE' | translate }}</b>
              <div>{{ billing.billingDetails.upgradePreview?.oldSubscription.package.name }}</div>
              <div>
                {{ billing.billingDetails.upgradePreview?.oldSubscription.package.pricing.recurringFee }}
                {{ billing.billingDetails.upgradePreview?.oldSubscription.package.pricing.currency }}/{{
                  billing.billingDetails.upgradePreview?.oldSubscription.package.contractTerms.billingPeriod
                }}
              </div>
            </flex-col>
            <!-- right (new) -->
            <flex-row style="width: 50%; background: linear-gradient(90deg, #0099a1 0%, #21b28b 100%); color: white; box-sizing: border-box">
              <svg viewBox="0 0 100 100" style="height: 100%; width: 20px" preserveAspectRatio="none">
                <path d="M0,0 L100,50 L0,100 z" fill="#eee"></path>
              </svg>
              <flex-col style="padding: 10px">
                <b>{{ 'STORE.NEW_PACKAGE' | translate }}</b>
                <div>{{ billing.billingDetails.upgradePreview?.newSubscription.package.name }}</div>
                <div>
                  {{ billing.billingDetails.upgradePreview?.newSubscription.package.pricing.recurringFee }}
                  {{ billing.billingDetails.upgradePreview?.newSubscription.package.pricing.currency }}/{{
                    billing.billingDetails.upgradePreview?.newSubscription.package.contractTerms.billingPeriod
                  }}
                </div>
              </flex-col>
            </flex-row>
          </flex-row>
        </div>
        <!-- info text -->
        <div style="width: 100%; margin: 20px 0">
          {{ 'STORE.CONTRACT_DURATION_INFO' | translate }}
        </div>
        <!-- upgrade math -->
        <div style="background: #eee; padding: 10px; width: 100%; box-sizing: border-box">
          <!-- top row: icon and service name -->
          <!-- old plan and math -->
          <!-- use cmp instead -->
          <app-cart-calculation [tsdUpgrade]="true"></app-cart-calculation>
        </div>
        <!-- user reference & address... -->
        <div style="width: 100%; margin-top: 20px">
          <!-- middle: free text reference -->
          <pc-input-textfield
            [control]="billing.upgradeReferenceControl"
            placeholder="Your reference (optional)"
            size="small"
            variant="fit-width"
            (valueChanged)="billing.upgradeReference = $event"></pc-input-textfield>
          <flex-row-between style="margin-top: -30px">
            <flex-col style="width: 45%">
              <div>
                <h4>{{ 'STORE.BILLING_ADDRESS' | translate }}</h4>
                <div>{{ billing.billingAccount?.companyName }}</div>
                <div>{{ billing.billingAccount?.firstName }} {{ billing.billingAccount?.lastName }}</div>
                <div>{{ billing.billingAccount?.address.street }} {{ billing.billingAccount?.address.houseNumber }}</div>
                <div>{{ billing.billingAccount?.address.postalCode }} {{ billing.billingAccount?.address.city }}</div>
                <div>{{ billing.countryMapping[billing.currentCountry] }}</div>
              </div>
            </flex-col>
            <flex-col style="width: 45%">
              <!-- TODO: Replace function with pipe! -->
              @if (billing.getContractPartner(); as contractPartner) {
                <div>
                  <h4>{{ 'STORE.CONTRACT_PARTNER' | translate }}</h4>
                  <div>{{ contractPartner.companyName }}</div>
                  <div style="white-space: pre">{{ contractPartner.companyAddress }}</div>
                </div>
              }
            </flex-col>
          </flex-row-between>
          <!-- bottom: billing address -->
          <h4>{{ 'STORE.CONFIRMATION_EMAIL' | translate }}</h4>
          <!-- t&c -->
          <flex-row>
            <!-- left: checkbox -->
            <div>
              <input type="checkbox" id="upgradeTermsCheck" #upgradeTermsCheck (change)="upgradeTermsAccepted = $event.target.checked" />
            </div>
            <!-- right: wording -->
            <div>
              <label
                for="termsCheckbox"
                style="cursor: default"
                [innerHtml]="
                  'STORE.TERMS_AND_CONDITIONS' | translate: { param1: billing.links.termsAndConditions, param2: billing.links.softwareLicenseTerms }
                "></label>
            </div>
          </flex-row>
        </div>
        <!-- full width content -->
        <flex-row-center style="width: 100%; margin-top: 20px; justify-content: flex-end">
          <pc-button
            [wide]="true"
            [text]="'STORE.BOOK_UPGRADE' | translate"
            [disabled]="!upgradeTermsAccepted"
            (btnClick)="onConfirmUpgradeClick()"></pc-button>
        </flex-row-center>
      </ng-container>
    </pc-overlay>
  }
</div>
